import React, { useEffect, useState } from "react";
import styles from "./BusinessPerformance.module.scss";
import ViewPlaybookLight from "../../../../assets/images/LightTheme/view_playbook.svg";
import ViewPlaybookDark from "../../../../assets/images/DarkTheme/view_playbook.svg";
import { useAppSelector } from "../../../../redux/hooks";
import ExpandAIInsightLight from "../../../../assets/images/LightTheme/expand_ai_insight.svg";
import ExpandAIInsightDark from "../../../../assets/images/DarkTheme/expand_ai_insight.svg";
import Summary from "./Summary/Summary";
import AGCDetailsTile from "../../../common/AGCDetailsTile/AGCTile";
import KPIsPill from "./KPIsPillSection/KPIsPill";
import SeperatorLineDark from "../../../../assets/images/DarkTheme/seperator_line.svg";
import SeperatorLineLight from "../../../../assets/images/LightTheme/seperator_line.svg";
import { isEmpty } from "lodash";
import AIInsight from "../../../common/AGCDetailsTile/InsightAI/InsightAI";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { downloadDocument } from "../../../../utils/common";
import { useGetAGCAISummaryQuery } from "../../../../redux/services/agcAIAPI";

const BusinessPerformance: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const { t } = useTranslation();
  const playbookImage = currentTheme === "light" ? ViewPlaybookLight : ViewPlaybookDark;
  const SeperatorIcon = currentTheme === "dark" ? SeperatorLineDark : SeperatorLineLight;
  const [isSummarySelected, setIsSummarySelected] = useState<boolean>(true);
  const [selectedKPIs, setSelectedKPIs] = useState<string[]>([]);
  const closingMonth: any =
    useSelector((state: RootState) => state?.businessPerformanceClosingMonth?.businessPerformanceClosingMonth) || '';
  const [isAPIError, setIsAPIError] = useState(false);
  const [agcSummaryData, setAGCSummaryData] = useState(null);

  const { data: agcAISummary, isFetching, isError } = useGetAGCAISummaryQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
    },
    {
      skip:
        userDetails.affiliateCode === 0 ||
        userDetails.aboNumber === 0 ||
        isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    setAGCSummaryData(agcAISummary?.body);
  }, [agcAISummary]);

  const selectDeselctFunctionality = (clickedKpis: string) => {
    const isAvailable = selectedKPIs.includes(clickedKpis);
    if (isAvailable) {
      const updatedArray = selectedKPIs.filter(name => name !== clickedKpis);
      setSelectedKPIs(updatedArray);
      if (isEmpty(updatedArray)) {
        setIsSummarySelected(true);
      }
    } else {
      setSelectedKPIs([...selectedKPIs, clickedKpis]);
      setIsSummarySelected(false);
    }
  };
  const selectPill = (name: string) => {
    selectDeselctFunctionality(name);
  };
  const selectSummaryPill = () => {
    setIsSummarySelected(true);
    setSelectedKPIs([]);
  };
  const handleTileClose = (updatedData: AGCProps[]) => {
    const updatedKpiNames = updatedData.map(kpi => (typeof kpi === 'string' ? kpi : kpi.kpiName));
    const newSelectedKPIs = selectedKPIs.filter(kpi => !updatedKpiNames.includes(kpi));
    setSelectedKPIs(newSelectedKPIs);
    if (newSelectedKPIs?.length === 0) {
      setIsSummarySelected(true);
    }
  };



  return (
    <div className={`business_performance ${styles.business_performance}`}>
      <div>
        <div className={`header ${styles.header}`}>
          <span className={`title ${styles.title}`}>{t("businessPerformance")}</span>
          <span onClick={() => downloadDocument("playbook")} className={`playbook_link ${styles.playbook_link}`}>
            <img
              src={playbookImage}
              alt="Playbook icon"
              className={`playbook_icon ${styles.playbook_icon}`}
            />
            <span className={`playbook_text ${styles.playbook_text}`}>
              {t("viewPlaybook")}
            </span>
          </span>
        </div>

        <div className={`last_closed_month_wrapper ${styles.last_closed_month_wrapper}`}>
          <span className={`last_closed_month ${styles.last_closed_month}`}>
            {t("Last_closed_month")} {closingMonth}
          </span>
        </div>

        {/* AIInsight component usage with dropdown */}
        {isFetching ? (
          <div 
            className={`${styles.ai_insight_wrapper} animate`}
          >
          </div>
        ) : ( 
          <AIInsight
            updateDate="08/19/2024"
            content={agcSummaryData}
            showDropdown={true}
            isDropdownEnabled={true}
            flowerIconSize={24}
            infoIconSize={12}
            title={"ai_summary"}
            titleSize={16}
          />
       )}
      </div>
    </div>
  );
};

export default BusinessPerformance;
